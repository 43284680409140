/* eslint-disable quote-props */
const messages = {
  'no_NO': {
    'Shared': {
      'compass': 'Kompass',
      'project': 'Prosjekt',
      'projects': 'Prosjekter',
      'folder': 'Mappe',
      'folders': 'Mapper',
      'category': 'Tema',
      'categories': 'Tema',
      'subcategory': 'Fokusområde',
      'subcategories': 'Fokusområder',
      'standard_action': 'Standard tiltak',
      'standard_actions': 'Standard tiltak',
      'action': 'Tiltak',
      'actions': 'tiltak',
      'ambition': 'Ambition',
      'vision': 'Målsetting',
      'effect': 'Effekt',
      'chooseFramework': 'Velg rammeværk',
      'recommended': 'Anbefales',
      'collected': 'Samlet ind',
      'collections': 'samlinger',
      'all': 'Alle',
      'library': 'Bibliotek',
      'custom': 'DIY',
      'edit': 'Rediger',
      'cancel': 'Avbryt',
      'close': 'Lukk',
      'try again': 'Prøv igjen',
      'save': 'Lagre',
      'saving': 'Lagrer',
      'saved': 'Lagret',
      'add': 'Legg til',
      'delete': 'Slett',
      'copy': 'Kopier',
      'new action': 'Nytt tiltag',
      'outside': 'Utenfor',
      'no_goals': 'Ingen målsettinger',
      'no_actions': 'Ingen tiltak',
      'create': 'opprett',
      'loading': 'Laster inn',
      'rating': 'Vurdering',
      'ratings': 'Vurderinger',
      'choose': 'Velg',
      'title': 'Tittel',
      'description': 'Beskrivelse',
      'icon': 'Ikon',
      'color': 'Farge',
      'controls': 'Handlinger',
      'search': 'Søk',
      'warning': 'Advarsel',
      'export': 'Eksporter',
      'next': 'Neste',
      'previous': 'Tilbake',
      'readMore': 'Les mer',
      'readLess': 'Les mindre',
      'points': 'Poeng',
      'add rating': 'Legg til vurdering',
      'add action to category': 'Legg til handling i kategorien',
      'hashtag': 'emneknagg',
      'hashtags': 'emneknagger',
      'report elements': 'Rapportelementer',
      'name': 'Navn',
      'email': 'E-post',
      'phone': 'Telefon',
      'pending invitation': 'Ventende invitasjon',
      'active': 'Aktiv',
      'user': 'Bruker',
      'admin': 'Admin bruker',
      'super admin': 'Super admin bruker',
      'shared': 'Delt',
      'private': 'Privat',
      'percent': 'Prosent',
    },
    'Header': {
      'Button01': 'Om LINK Arkitektur',
      'Button02': 'Om LINK Kompass',
      'Button03': 'Prosjekter',
      'Button04': 'Logg inn',
      'Button04_alt': 'Logg ut',
      'Button05': 'Bytt passord',
      'Button06': 'Tiltaks designer',
      'Button07': 'Cms',
      'Button08': 'Organisasjoner',
      'Button09': 'Brukere',
      'Button10': 'Support',
    },
    'Projects': {
      'Shared': {
        'project_title': 'Prosjekttittel',
        'project_number': 'Prosjektnummer',
        'project_description': 'Prosjektbeskrivelse',
        'project_goals': 'Prosjektmålsettinger',
        'project_folder': 'Prosjektmappe',
      },
      'Project list view': {
        'Title': 'Mine prosjekter',
        'Button01': 'Nytt prosjekt',
        'MessageNoProjects': 'Ingen prosjekter. Legg til et nytt prosjekt.',
        'SharedWithMe': 'Delt med meg',
      },
      'Project view': {
        'Button01': 'Workshop',
        'Button01_description': 'Start en workshop – her kan du samle inn, tilføye og redigere prosjektmålsettinger og tiltak.',
        'Button02': 'Rediger prosjekt',
        'Button02_description': 'Redigere tittel og beskrivelse av prosjekt.',
        'Button03': 'Utforsk',
        'Button03_description': 'Start en utforsk-økt – du kan det samme som i en workshop, men resultatene lagres ikke.',
        'Button04': 'Del',
        'Button04_description': 'Del projekt med en kollega - brugeren får samme rettigheder til at redigere projektet som dig.',
        'Button05': 'Kopier',
        'Button05_description': 'Lav en kopi af prosjekt',
        'Button06': 'Online rapport',
        'Button06_description': 'Lag en online rapport',
        'Button07': 'Eksporter',
        'Button07_description': 'Eksporter prosjektdata til forskjellige formater',
        'Tab01': 'Prosjektinformasjon',
        'Tab02': 'Tema og tiltak',
        'SectionTitle01': '@:Projects.Shared.project_description',
        'SectionTitle02': '@:Projects.Shared.project_goals',
        'MessageNoGoals': '@:Shared.no_goals',
        'DeleteProjectConfirm': 'Er du sikker på at du vil slette dette prosjektet og alle dets handlinger og mål?',
        'ProjectOwner': 'Prosjekteier',
        'ProjectCountry': 'Prosjektland',
        'MessageNoCountry': 'Ingen land valgt',
      },
      'Edit project popup': {
        'Title': 'Rediger prosjekt',
        'Label01': '@:Projects.Shared.project_title',
        'Label02': '@:Projects.Shared.project_description',
        'Label03': '@:Projects.Shared.project_number',
        'Button01': '@:Shared.cancel',
        'Button02': 'Slett prosjekt',
        'Button03_loading': '@:Shared.saving',
        'Button03': '@:Shared.save',
        'country_select_no_country': 'Ingen land',
      },
      'New project popup': {
        'Title': 'Nytt prosjekt',
        'Label01': '@:Projects.Shared.project_title',
        'Label02': '@:Projects.Shared.project_description',
        'Label03': '@:Projects.Shared.project_number',
        'Label04': '@:Projects.Shared.project_folder',
        'Label05': 'Ny prosjektmappe',
        'Label06': 'Legg til samling av tiltak fra start',
        'Label07': 'Velg samling',
        'Select_country_label': 'Velg prosjektland',
        'Select_country_placeholder': 'Ingen land',
        'Placeholder01': 'Prosjektgruppetittel',
        'Button01': '@:Shared.cancel',
        'Button02': '@:Shared.create',
        'country_select_no_country': 'Ingen land',
      },
      'Share project popup': {
        'Title': 'Del prosjekt',
        'Manchet': 'Skriv emailen på den du ønsker at dele projektet med. Personen får adgang til projektet på lige vilkår med dig. (Men kan dog ikke dele det videre)',
        'InfoMessage01': 'Vær opmærksom på at den email du bruger skal være den eksakte mail som brugeren har oprettet sig med. Mail alias kan ikke bruges.',
        'Label01': 'E-post adresse',
        'Label02': 'Delt med',
        'Button01': 'Del',
        'Button02': 'Luk',
      },
      'Copy project popup': {
        'Title': 'Kopier prosjekt',
        'Manchet': 'Et nyt prosjekt vil bli opprettet med samme innhold som dette prosjektet. Du vil være eier av det nye prosjektet.',
        'SuccessMessage': 'Prosjektet er kopiert',
        'ErrorMessage': 'Kunne ikke kopiere prosjektet',
        'Button01': '@:Shared.cancel',
        'Button02': '@:Shared.copy',
        'Button03': 'Gå til det nye prosjektet',
        'Button04': 'Prøv igjen',
      },
      'Version warning popup': {
        'Message': 'Dette projekt er gemt i en gammel version af Link Kompass. Ønsker du at opgradere projektet og få adgang til nyeste funktionalitet? (OBS! Du kan ikke længere se eller rette ambitionspoint på fokusområder)',
        'Button01': 'Fortsæt i gammel version',
        'Button02': 'Opgrader projekt',
      },
    },
    'Workshop': {
      'Full screen rose chart': {
        'vision_and_effect_rose': 'Visjons- och effektrose',
        'select_framework': 'Velg rammeværk',
      },
      'Action': {
        'text': 'Tekst',
        'no_text': 'Ingen tekst',
        'tooltip_action_activate': 'Aktiver/deaktiver tiltak. (Deaktiverte tiltak inngår ikke i kompasset og er ikke synlige utenfor workshop)',
        'tooltip_action_park': 'Sett tiltak på vent til senere bruk. (Det finnes under P-ikonet på venstre side av skjermen)',
        'tooltip_action_edit': 'Rediger beskrivelse og vurdering av tiltaket',
        'tooltip_action_delete': 'Slett tiltaket (predefinerte tiltak kan opprettes igjen fra bilioteket)',
        'tooltip_action_remove': 'Fjern tiltak',
        'tooltip_action_add': 'Legg til tiltak',
      },
      'Goal': {
        'description': 'Beskrivelse',
      },
      'Page header': {
        'Title_normal': 'Workshopøkt',
        'Title_explore': 'Utforskningsøkt',
        'Button01_normal': 'Avslutt workshop',
        'Button01_explore': 'Avslutt økt',
        'Button02_show': 'Vis verktøytips',
        'Button02_hide': 'Skjul verktøytips',
        'Button03': 'Innsamlingslenke',
        'Button04': 'Eksporter',
        'Button05A': 'Åpne fullskjermmodus',
        'Button05B': 'Lukk fullskjermmodus',
        'Tab01': 'Prosjektmålsettinger',
        'Tab02': 'Kompass',
      },
      'Steps Navigation Toolbar': {
        'Button01': 'Forrige workshop trinn',
        'Button02': 'Velg workshop flow',
        'Button03': 'Neste workshop trinn',
      },
      'Steps': {
        'Step1Label': 'Lav visionsboard',
        'Step1Description': 'asdfælkjasdf',
        'Step2Label': 'Tegn visionsrose',
        'Step2Description': 'asdfælkjasdf',
        'Step3Label': 'Temaer',
        'Step3Description': 'asdfælkasdjfælkasf',
        'Step4Label': 'Gennemse workshop resultater',
        'Step4Description': 'asdfælfksdj',
        'StepGuideSubStep1Label': 'Intro',
        'StepGuideSubStep2Label': 'Fokusområder',
        'StepGuideSubStep3Label': 'Tiltak',
        'StepGuideSubStep4Label': 'Ambitionsniveau',
      },
      'Compass panel': {
        'button_fullscreen_compass': 'Åpne compass i fullskjerm',
        'button_expand_panel': 'Vis panel',
        'button_collapse_panel': 'Skjul panel',
      },
      'Summary panel': {
        'PanelTitle': 'Overblik',
        'Section1Title': 'Visionsboard',
        'Section2Title': 'Målsetting- og effektpoint',
      },
      'StepVisionboard': {
        'Question': 'Hvordan ser bærekraftsvisjonen ut for dette prosjektet?',
        'Description': 'Beskriv ved å legge til signaler, setninger og bilder på lerretet nedenfor.',
        'Frame01': 'Notater og bilder som beskriver visjonen',
        'Frame02': 'Klikk hvor som helst for å legge til notat eller bilde',
        'Modal01': 'Legg til notat eller bilde',
        'Button01': 'Legg til notat',
        'Button02': 'Legg til bilde',
      },
      'StepVisionrose': {
        'Question': 'Hvor store er jeres målsettinger for hvert tema?',
        'Description': 'Klik eller træk på kompassets for at tegne jeres målsetting',
        'Tab01': 'Temabeskrivelser',
        'Tab02': 'Flere kompasser',
        'Explanation01': 'Ambisjonsnivået defineres manuelt ved å «dra» i rosen å gi en verdi mellom 1-100.',
        'Explanation02': '1 = svært lite viktig,',
        'Explanation03': '50 = middels viktig',
        'Explanation04': '100 = svært viktig.',
      },
      'StepThemeGuideFocusAreas': {
        'Title': 'velge fokusområder',
        'Question': 'Hvilke fokusområder vil du prioritere innenfor dette temaet?',
        'Description': 'Velg en rekke fokusområder nedenfor og vurder ambisjonsnivået ditt for hvert område.',
        'Section1Title': 'Mulige fokusområder',
        'Section2Title': 'Utvalgte fokusområder',
        'AddArea': 'Legg til fokusområde',
        'CustomTitle': 'Titel på fokusområde',
        'CustomDescription': 'Beskrivelse av fokusområde',
        'ConfirmDelete': 'Er du sikker på at du vil slette dette fokusområdet? (tiltak under dette fokusområdet vil bli slettet)',
      },
      'StepThemeGuideAmbition': {
        'Title': 'legge til tiltak',
        'Question': 'Hvor er dit ambitionsniveau for dette tema?',
        'Description': 'Sæt et ambitionsniveau der passer til dit projekt og se foreslåede tiltag baseret på dine valgte fokusområder og ambitionsniveau',
        'Section_ambition_rating_title': 'Ambisjonsnivå',
        'Section_recommended_actions_title': 'Anbefalte tiltak',
        'Section_ambition_rating_unit': 'Prosent',
        'Section_ambition_rating_description': 'Ambisjonsnivået defineres manuelt ved å «dra» i rosen å gi en verdi mellom 1-100. <br />1 = svært lite viktig, <br />50 = middels viktig, <br />100 = svært viktig.',
      },
      'StepThemeGuideActions': {
        'Title': 'legge til tiltak',
        'Question': 'Hvilke tiltak vil du ta for å nå dine ambisjoner for fokusområdene?',
        'Description': 'Velg tiltak fra listen og dra dem over til de enkelte fokusområdene.',
        'AvailableActions': 'Mulige tiltak',
        'SelectedActions': 'Utvalgte tiltak',
        'AddAction': 'Legg til tiltag',
      },
      'StepSummary': {
        'Question': 'Slik ser prosjektets bærekraftsprofil ut',
        'Description': 'Du kan eksportere profilen som en rapport',
        'BarChartTitle': 'Tiltak per tema',
      },
      'Goals view': {
        'Button01': 'Legg til målsetting',
      },
      'Matrix view': {
        'Button01': 'Legg til tiltak',
        'Button01_tooltip': 'Legg til forslag til tiltak og flytt til kompass eller sett på vent til senere bruk (ALT+CTRL+t)',
        'Button02_hide': 'skjul tiltak',
        'Button02_show': 'vis tiltak',
        'Button02_tooltip': 'Skjul alle tiltak for å få bedre oversikt over temaer og undertemaer',
        'Button03_all': 'vis alle undertema',
        'Button03_chosen': 'vis bare valgte undertema',
        'Button03_tooltip': 'Få mer oversikt ved å sortere undertemaer slik at du bare ser de som er valgt (du kan velge et undertema ved å klikke på tittelen)',
        'Button04_tooltip': 'Zoom inn (ALT+CTRL+i)',
        'Button05_tooltip': 'Zoom ut (ALT+CTRL+u)',
        'Compass': 'Kompass',
      },
      'Category view': {
        'Button01': 'Legg til tiltak',
        'Button01_tooltip': 'Legg til forslag til tiltak og flytt til kompass eller sett på vent til senere bruk (ALT+CTRL+t)',
        'Button02_all': 'vis alle undertemaer',
        'Button02_chosen': 'vis bare valgte undertemaer',
        'Button02_tooltip': 'Få mer oversikt ved å sortere undertemaer slik at du bare ser de som er valgt (du kan velge et undertema ved å klikke på tittelen)',
      },
      'Export popup': {
        'Button01': '@:Shared.cancel',
        'Button02': 'Eksporter word',
        'Button03': 'Eksporter CSV',
        'Button04': 'Eksporter bilde',
      },
      'Collect actions link popup': {
        'Title': 'Lenke til innsamling av brukerforslag',
        'Button01': '@:Shared.cancel',
      },
      'Edit action popup': {
        'Title': 'Rediger tiltak',
      },
      'Add action popup': {
        'Title': 'Legg til nytt forslag til tiltak',
        'No_actions': 'Ingen tiltak matchet søket. Prøv et annet søk.',
        'Textarea01_placeholder': 'Beskrivelse av tiltaket',
        'Button01': '@:Shared.cancel',
        'Button02': 'Legg til og sett på vent',
        'Button03': 'Legg til',
      },
      'Edit workshop steps popup': {
        'Title': 'Vælg relevante trin til din workshop',
        'Description': 'Du kan tilføje trin (delprocesser) til din workshop ved at vælge fra listen til højre. Nogle trin har også under-trin som du kan vælge tril eller fra. Du kan godt tilføje det samme trin mere end en gang. Dette kan f.eks. give mening hvis du ønsker at have én temaguide med fokus på fokusområder og en med fokus på tiltag.',
        'Section1Title': 'Valgte workshoptrin',
        'Section2Title': 'Mulige workshoptrin',
        'SaveButton': 'Gem',
        'CancelButton': 'Fortryd',
        'ValidationError1': 'Du mangler at vælge mindst en undersection på et af de valgte trin',
      },
      'Mobile user warning popup': {
        'Message': 'Workshopøkt fungerer ikke på mobil',
        'BackButton': 'Tilbake',
      },
      'Chart panel': {
        'Button01_tooltip': 'Vis kompass med en visuell oversikt over godkjente tiltak og deres vurdering',
        'SectionTitle01': 'Tiltag',
        'SectionTitle02': 'Prioritering af temaer',
        'SectionTitle03': 'Valgte målsettinger',
      },
      'Library panel': {
        'Button01_tooltip': 'Vis / lukk biblioteket med forslag til tiltak',
        'Dropdown01_placeholder': 'Velg tema',
        'Dropdown02_placeholder': 'Velg undertema',
        'Dropdown03_placeholder': 'Alle tema',
        'Dropdown04_placeholder': 'Alle samlinger',
        'Toggle01_label': 'Vis anbefalinger øverst',
        'InfoMessage01': 'Vær oppmerksom på at du godt kan legge til det samme tiltaket i flere kategorier, tiltaket vil da telle i begge kategoriene hvor det er lagt til. Har du lagt til det samme tiltaket under forskjellige kategorier kan du etterfølgende endre i de to tiltakenes rangering uavhengig av hverandre.',
        'MessageNoActions': 'Ingen tiltak i denne kategorien',
        'Error01': 'Kunnne ikke laste inn tiltak',
      },
      'Collected actions panel': {
        'InfoMessage01': 'Her kan du se tiltak som har blitt samlet inn via innsamlingslenken (Finn innsamlingslenken ved å klikke på talebobleikonet øverst til høyre). Tilføyde tiltak vil bli plassert i kategorien "Annet". ',
      },
      'Parking panel': {
        'Button01_tooltip': 'Vis / lukk tiltak på vent. Her kan du sette tiltak på vent for senere bruk',
        'Dropdown01_placeholder': 'Velg tema',
        'Dropdown02_placeholder': 'Velg undertema',
        'MessageInfo': 'Du kan plassere foreslåtte tiltak til senere diskusjon enten ved å dra tiltaket over i parkeringsplassen eller ved å velge tiltaket og klikke på parkeringsikonet',
      },
      'User actions panel': {
        'Button01_tooltip': 'Vis / lukk liste over innsamlede forslag fra brukere. Her er også instruksjoner om hvordan du samler inn forslag.',
        'MessageInfo': 'Du kan samle inn forslag fra workshopdeltakere ved å klikke på \'Innsamlingslenke\' øverst i høyre hjørne',
      },
    },
    'Report': {
      'Headers': {
        'Title': 'Online rapport',
        'Actions': 'Tiltak',
        'Board': 'Prosjektets visjon',
      },
      'Elements': {
        'Tab01': 'Kompass',
        'Tab02': 'Tema',
        'Tab03': 'Layout',
        'Project title': 'Prosjekt tittel',
        'Project description': 'Prosjekt beskrivelse',
        'Project number': 'Prosjekt nummer',
        'board': 'Visjonsboard',
        'intro': 'Tema introduksjon',
        'actions': 'Tiltak',
        'subcategories': 'Fokusområder',
        'compass': 'Kompass',
        'h1': 'Heading 1',
        'h2': 'Heading 2',
        'h3': 'Heading 3',
        'paragraph': 'Paragraph',
        'threecolumns': 'Layout 3 cols',
        'twocolumns': 'Layout 2 cols',
      },
      'Toolbar': {
        'Button01': 'Lagre rapport',
        'Button02': 'Del rapport',
        'Button03': 'Kopier rapport',
        'Button04': 'Forhåndsvisning',
        'Button05': 'Oppdater kompassdata i rapporten',
        'Button06': 'Last inn standardrapport',
      },
      'Share': {
        'Title': 'Del rapport',
        'Manchet': 'Del rapporten med andre ved å kopiere lenken under og aktivere',
        'Expiration': 'Valgfri utløpsdato for rapporten',
        'Visibility': 'Personer med lenken kan se dataene',
        'Button01': 'Avbryt',
      },
      'Preview': {
        'Title': 'Forhåndsvisning',
        'Button01': 'Avbryt',
      },
      'SharedReport': {
        'Title': 'Online rapport',
        'Label01': 'Prosjekt tittel',
        'Label02': 'Prosjekt nummer',
        'Label03': 'Rapport oppdatert',
      },
    },
    'Explore mobile': {
      'Choose project popup': {
        'Title01': '@:Shared.projects',
        'Manchet01': 'Velg mellom prosjekteksempler som utgangspunkt eller opprett et nytt prosjekt',
        'EmptyProjectTitle': 'Opprett et nytt prosjekt',
      },
      'Categories and actions view': {
        'Tab01': 'Tema',
        'Tab02': 'Tiltak',
        'MessageNoActions': 'Legg til tiltak ved å klikke på \'+ Tiltak\' øverst i høyre hjørne',
      },
      'Panel': {
        'Tab01': 'Fra bibliotek',
        'Tab02': 'Tilpasset',
        'Textarea01_placeholder': 'Beskriv tiltaket',
        'Label01': 'Hvilket tema hører forslaget ditt under?',
        'Button01': 'Legg til tiltak',
        'Button02': 'Fjern markeringen av tiltak',
        'Button03': 'Legg til valgte tiltak',
        'Button04': 'Hent flere tiltak',
        'Button05': 'Legg til tiltak',
        'MessageAllAdded': 'Alle tiltak i denne kategorien er lagt til i kompasset',
      },
    },
    'Guest': {
      'Contributions view': {
        'Title': 'Bidra med forslagene dine',
        'Button01': 'nytt forslag',
        'MessageNoSuggestions': 'klikk på knappen: "nytt forslag"',
      },
      'Add suggestion popup': {
        'Title': 'Legg til nytt forslag til tiltak',
        'Textarea01_placeholder': 'Beskriv forslaget ditt',
        'Button01': '@:cancel',
        'Button02': 'Sende inn',
      },
    },
    'Users': {
      'Login view': {
        'MessageLoading': '@:Shared.loading',
        'Title01': 'Logg inn',
        'Title02': 'Logg inn med e-post adresse',
        'Label01': 'E-post adresse',
        'Input01_placeholder': 'linkarkitektur mail',
        'Label02': 'Passord',
        'Input02_placeholder': 'Passord',
        'Button01': 'Logg inn',
        'Button01_loading': 'Logger inn',
        'Button02': 'Logg inn med e-post adresse',
        'Button02_loading': '',
        'Link01': 'Oprette ny bruker?',
        'Link02': 'Glemt passord?',
        'Link02_alt': 'Logg inn med passord',
        'MessageEmailSent': 'E-post er sendt',
        'ErrorMessage01': 'Kan ikke logge ind. Tjek dine oplysninger',
        'ErrorMessage02': 'Der opstod en fejl ved login. Prøv igen.',
      },
      'Signup view': {
        'Title01': 'Oprett ny bruker',
        'Label01': 'Fullt navn',
        'Input01_placeholder': 'Kari Normann',
        'Label02': 'E-post adresse',
        'Input02_placeholder': 'linkarkitektur mail',
        'Label03': 'Passord',
        'Input03_placeholder': 'Passord',
        'Button01': 'Opprett',
        'InfoMessage01': 'Vær opmærksom på at systemet ikke kender eventuelle email alias. Det er altså den eksakte email du skriver her der senere skal bruges til at logge ind, samt hvis andre vil dele et projekt med dig.',
        'ErrorMessage01': 'Domain not allowed',
        'ErrorMessage02': 'Kunne ikke logge ind. Det kan f.eks. skyldes at din bruger allerede eksisterer.',
      },
      'Password view': {
        'Input_new_password_label': 'Nytt passord',
        'MessageSuccess': 'Passordet ble endret',
      },
    },
    'Organizations': {
      'All organizations view': {
        'Organization_card_contact_person': 'Kontaktperson',
        'Organization_card_contact_phone': 'Telefonnummer',
        'Organization_card_contact_email': 'E-post adresse',
        'Section_admin_users_title': 'Admin brukere',
      },
      'Create organisation popup': {
        'Title': 'Ny organisasjon',
        'Section_org_name_title': 'Organisasjonsnavn',
        'Input_org_name_validation': 'Organisasjonsnavn er påkrevd.',
        'Section_main_contact_title': 'Hovedkontakt',
        'Input_main_contact_name_placeholder': 'Navn',
        'Input_main_contact_name_validation': 'Navn er påkrevd.',
        'Input_main_contact_email_placeholder': 'E-post',
        'Input_main_contact_email_validation': 'Ugyldig e-postformat.',
        'Input_main_contact_phone_placeholder': 'Telefon',
        'Input_main_contact_phone_validation': 'Telefonnummer er påkrevd.',
        'Section_org_admins_title': 'Admin brukere',
        'Section_org_admins_description': 'Inviter en eller flere personer til å bli admin i den nye organisasjonen',
        'Input_org_admins_validation': 'Navn og e-post er påkrevd for alle administratorer.',
        'Form_action_create': 'Opprett organisasjon',
        'Status_success': 'Organisasjonen ble opprettet',
        'Status_error': 'Kunne ikke opprette organisasjonen',
        'Status_loading': 'Oppretter organisasjon...',
        'Status_all_fields_required': 'Alle felt er påkrevd',
      },
      'Edit organization popup': {
        'Title': 'Rediger organisasjonsinfo',
        'Section_org_name_title': 'Organisasjonsnavn',
        'Section_main_contact_title': 'Hovedkontakt',
        'Input_main_contact_name_placeholder': 'Navn',
        'Input_main_contact_email_placeholder': 'E-post',
        'Input_main_contact_phone_placeholder': 'Telefon',
        'Section_more_info_title': 'Mer info',
        'Info_created_label': 'Opprettet',
        'Info_total_users_label': 'Totalt antall brukere',
        'Info_total_compasses_label': 'Totalt antall kompasser',
        'Section_users_title': 'Brukere',
        'Button_edit': '@:Shared.edit',
        'Button_cancel': '@:Shared.cancel',
        'Button_save': '@:Shared.save',
        'Status_success': 'Organisasjonen ble oppdatert',
        'Status_error': 'Oppdatering av organisasjonen feilet',
        'Status_loading': 'Oppdaterer organisasjon...',
        'Status_no_change': 'Ingen endringer ble gjort',
        'Confirm_active_admin': 'Siden brukeren er aktiv, vil dette ikke fjerne brukeren helt, men tilbakekalle admin-rettigheter?',
        'Confirm_inactive_admin': 'Siden brukeren ennå ikke er aktivert, vil dette fjerne brukeren og gjøre invitasjonen ugyldig?',
        'Message_cant_delete_active_user': 'På dette tidspunktet kan du ikke slette aktive brukere her. Du kan slette brukere i din egen organisasjon under brukeradministrasjonen.',
      },
    },
    'UserAdmin': {
      'User list': {
        'Title': 'Brukere',
        'Button_create_user_label': 'Opprett ny bruker i organisasjonen din',
      },
      'Edit user popup': {
        'Title': 'Rediger bruker',
        'Section_user_name_title': 'Brukernavn',
        'Section_contact_info_title': 'Kontaktinformasjon',
        'Input_contact_email_placeholder': 'E-post',
        'Input_contact_phone_placeholder': 'Telefon',
        'Button_delete': 'Slett',
        'Button_edit': 'Rediger',
        'Button_cancel': 'Avbryt',
        'Button_save': 'Lagre',
        'Button_make_admin': 'Gjør til admin',
        'Button_revoke_admin': 'Fjern admin',
        'Status_success': 'Brukeren ble oppdatert',
        'Status_error': 'Oppdatering av brukeren feilet',
        'Status_loading': 'Oppdaterer bruker...',
        'Status_no_change': 'Ingen endringer ble gjort',
        'Confirm_delete_user': 'Er du sikker på at du vil slette denne brukeren?',
        'Confirm_make_admin': 'Er du sikker på at du vil gjøre denne brukeren til admin?',
        'Confirm_revoke_admin': 'Er du sikker på at du vil fjerne admin-rettigheter fra denne brukeren?',
        'Confirm_revoke_own_admin': 'Er du sikker på at du vil fjerne dine egne admin-rettigheter?',
      },
      'Create user popup': {
        'Title': 'Opprett ny bruker',
        'Section_user_name_title': 'Brukernavn',
        'Section_contact_info_title': 'Kontaktinformasjon',
        'Input_contact_email_placeholder': 'E-post',
        'Input_contact_phone_placeholder': 'Telefon',
        'Form_action_create': 'Opprett bruker',
        'Status_success': 'Brukeren ble opprettet',
        'Status_error': 'Kunne ikke opprette brukeren',
        'Status_loading': 'Oppretter bruker...',
        'Status_all_fields_required': 'Alle felt er påkrevd',
        'Input_user_name_validation': 'Brukernavn er påkrevd.',
        'Input_contact_email_validation': 'Ugyldig e-postformat.',
        'Input_contact_phone_validation': 'Telefonnummer er påkrevd.',
      },
    },
    'Collections': {
      'Add collection': 'Legg til samling',
      'New collection': 'Ny samling',
    },
    'Action designer': {
      'Tabs': {
        'All actions': 'Alle tiltak',
        'Collections': 'Samlinger',
      },
      'New collection popup': {
        'Title': 'Ny samling',
        'Label01': 'Samlingens navn',
        'Button01': 'Avbryt',
        'Button02': 'Lagre',
        'Created message': 'Ny samling opprettet',
        'Status_loading': 'Oppretter samling...',
        'Status_success': 'Samlingen ble opprettet',
        'Status_error': 'Kunne ikke opprette samlingen',
      },
      'Actions': {
        'button_add_action': 'Legg til tiltak',
        'delete_action_warning': 'Er du sikker? Når du sletter en standard handling, vil den ikke lenger være synlig i biblioteket, og den vil bli fjernet fra samlinger. Kompasser hvor handlingen ble lagt til vil ikke endres.',
        'no_collections_message': 'ikke i noen samlinger',
        'missing_sub_category_message': 'Mangler fokusområde',
      },
      'Collection': {
        'Button01': 'Lag en kopi',
        'Button02': 'Slett',
        'Button01_tooltip': 'Lag en kopi av en samling.',
        'Button02_tooltip': 'Slett samling, for alle brugere.',
        'isEnabled': 'Samlingen er aktivert',
        'isNotEnabled': 'Samlingen er i utkastmodus',
        'DeleteConfirm': 'Er du sikker på at du vil slette denne samlingen?',
      },
      'Manage collection access popup': {
        'title': 'Administrer samlingstilgang',
        'Section_visibility_description': 'Velg hvem som har lov til å bruke denne samlingen',
        'Select_visibility_placeholder': 'Velg synlighet',
        'Section_organization_description': 'Velg i hvilke land samlingen er synlig. Landet er satt når du oppretter et nytt kompass.',
        'Section_organizations_description': 'Velg hvilke organisasjoner som har tilgang',
        'Select_visibility_option_private_label': 'Kun meg',
        'Select_visibility_option_organization_label': 'Alle i organisasjonen min',
        'Select_visibility_option_organizations_label': 'Valgte organisasjoner',
        'Select_visibility_option_public_label': 'Alle kan bruke',
        'Update successful': 'Oppdatering vellykket',
        'Update failed': 'Kunne ikke oppdatere',
      },
      'Copy collection popup': {
        'Title': 'Kopier samling',
        'Manchet': 'En ny samling med de samme handlingene vil bli opprettet.',
        'SuccessMessage': 'Samlingen ble kopiert',
        'ErrorMessage': 'Kunne ikke kopiere samlingen',
        'Button01': '@:Shared.cancel',
        'Button02': '@:Shared.copy',
        'Button03': 'Gå til den nye samlingen',
        'Button04': 'Prøv igjen',
      },
    },
    'Cms': {
      'Shared': {
        'MessageNoTranslation': 'Ingen oversætting',
      },
      'Main view': {
        'Tab01': 'Standard tiltak',
        'Tab02': '@:Shared.category & @:Shared.subcategory',
        'Tab03': 'Tekster',
        'Tab04': 'Rapport tekstbidder',
        'Tab05': 'Frameworks',
      },
      'Standard actions view': {
        'Button01': 'Legg til standard tiltak',
        'Button03': 'Hent flere tiltak',
      },
      'Categories view': {
        'Button01': '@:Shared.edit @:Shared.category',
        'Button02': '@:Shared.edit @:Shared.subcategory',
        'MessageNoTranslation': 'No translation',
      },
      'Category view': {
        'Button01': 'Rediger tema',
        'Button02': 'Slett undertema',
        'Button03': 'Rediger undertema',
      },
      'Edit category popup': {
        'Title': 'Rediger @:Shared.category',
        'Label01': '@:Shared.category titel',
        'Label01A': 'Reference tekst',
        'Label02': '@:Shared.category beskrivelse',
        'Label03': 'Farve hex kode',
        'Label03A': 'Farve eksempel',
        'Button01': '@:Shared.cancel',
        'Button02': 'Slett @:Shared:category',
        'Button03': '@:Shared.save',
        'Button03_loading': '@:Shared.saving',
      },
      'Edit subcategory popup': {
        'Title': 'Rediger @:Shared.subcategory',
        'Label01': '@:Shared.subcategory titel',
        'Label01A': 'Reference tekst',
        'Label02': '@:Shared.subcategory beskrivelse',
        'Label03': 'Farve hex kode',
        'Label03A': 'Farve eksempel',
        'Button01': '@:Shared.cancel',
        'Button02': 'Slett @:Shared.subcategory',
        'Button03': '@:Shared.save',
        'Button03_loading': '@:Shared.saving',
        'Button03_saved': 'Lukk',
      },
      'Edit standard action popup': {
        'Title': 'Rediger @:Shared.standard_action',
        'Label01': '@:Shared.description',
        'Label01A': 'Reference tekst',
        'Label02': '@:Shared.ratings',
        'Reference info': 'Referanseteksten er bare her for å gjøre oversettelsen enklere',
        'Button01': '@:Shared.cancel',
        'Button02': 'Slett @:Shared.action',
        'Button03': '@:Shared.save',
        'Button03_loading': '@:Shared.saving',
        'Button03_saved': 'Lukk',
      },
    },
    'Frameworks': {
      'LINK Bærekraftstemaer': 'LINK Bærekraftstemaer',
      'Impact Architecture': 'Impact Architecture',
      'FNs bærekraftsmål': 'FNs bærekraftsmål',
    },
  },
}

export default messages
