<template>
  <ul class="main navigation flex gap-8">
    <!-- <li><router-link :to="'/om'">{{ $t('Header.Button01') }}</router-link></li> -->
    <!-- <li><router-link :to="'/kompass'">{{ $t('Header.Button02') }}</router-link></li> -->
    <li v-if="user"><router-link :to="'/mine-projekter'">{{ $t('Header.Button03') }}</router-link></li>
    <li v-if="user"><router-link :to="'/ams'">{{ $t('Header.Button06') }}</router-link></li>
    <li v-if="user"><router-link :to="'/support'">{{ $t('Header.Button10') }}</router-link></li>
    <li v-if="isSuperAdmin"><router-link :to="'/cms'">{{ $t('Header.Button07') }}</router-link></li>
    <li v-if="isSuperAdmin"><router-link :to="'/admin/organizations'">{{ $t('Header.Button08') }}</router-link></li>
    <li v-if="isSuperAdmin || isAdmin"><router-link :to="'/admin/users'">{{ $t('Header.Button09') }}</router-link></li>
    <!-- <li v-if="user"><router-link :to="'/'">Min Profil</router-link></li> -->
  </ul>
</template>

<script>
export default {
  data () {
    return {}
  },
  computed: {
    user () {
      return this.$store.state.auth.user
    },
    isSuperAdmin () {
      return this.$store.getters['auth/isAuthenticated'] && this.$store.getters['auth/user'].roleId === 3
    },
    isAdmin () {
      return this.$store.getters['auth/isAuthenticated'] && this.$store.getters['auth/user'].roleId === 4
    },
  },
}
</script>

<style scoped>
li a.router-link-active {
  border-bottom: 1px solid currentColor;
}
</style>
